


















































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import LsShowPagination from "@/components/ls-pagination.vue";
import {
    showBatchList,
    cinemaStatus,
    setCinemaPrice,
    cinemaPickOut,
} from "@/api/movie";
import { apiShopLists } from "@/api/shop";
import LsDialog from "@/components/ls-dialog.vue";

import { RequestPaging } from "@/utils/util";
import { CinemaStatus } from "@/utils/type";

import shopTransfer from "@/components/transfer.vue";
import { deepClone } from "@/utils/util.ts";
import setMoviecityPrice from "@/components/set-moviecity-price.vue";
@Component({
    components: {
        LsDialog,
        LsPagination,
        LsShowPagination,
        shopTransfer,
        setMoviecityPrice,
    },
})
export default class CinemaPane extends Vue {
    $refs!: { priceForm: any; paneTable: any; tableForm: any };
    @Prop() value: any;
    @Prop() pager!: any;
    @Prop() tab!: any;
    dialog_title: string = "";
    dialogShop: boolean = false;
    dialogPrice: boolean = false;
    is_pick: boolean = false;
    price_loading: boolean = false;

    pickForm: any = {
        cinema_id: "",
        shop_data: [],
    };
    priceForm: any = {
        shop_price_type: "",
        price_method: "",
        value: {
            price_list: {
                price_type: "1",
                price_ratio: "1",
                price_add_sub: "1",
                price_number: "1",
            },
            price_rounding: "1",
            reserve_decimal: 2,
        },
    };
    show_details = false;
    pick_out_dialog = false;
    cinema_name: string = "";
    showDate = new Date();
    cinema_id: number = 0;
    uploadShow: Boolean = false;
    loading: Boolean = false;
    show_loading: Boolean = false;
    dialog_loading: Boolean = false;
    pick_out_dialog_loading: Boolean = false;

    shop_list: any[] = [];
    shop_data: any[] = [];
    selectIds: any[] = [];

    show_pager = new RequestPaging({ page: 1, size: 10 });

    get disabledBtn() {
        return !this.selectIds.length;
    }

    checkCinema(ids: number, status: number) {
        this.loading = true;
        cinemaStatus({ id: ids, status: status }).then((res) => {
            this.loading = false;
            this.uploadShow = true;
            this.$emit("refresh");
        });
        this.loading = false;
    }

    // 排期
    showDetails(cinema_id: number, cinema_name: string) {
        this.show_details = true;
        this.cinema_id = cinema_id;
        this.cinema_name = cinema_name;
        this.showDate = new Date();
        this.show_loading = true;
    }

    handleClose() {
        this.show_details = false;
        this.loading = false;
    }

    showList(page?: number) {
        page && (this.show_pager.page = page);
        this.show_loading = true;
        this.show_pager
            .request({
                callback: showBatchList,
                params: {
                    cinema_id: this.cinema_id,
                    show_date: this.showDate,
                },
            })
            .then((res: any) => {
                this.show_details = true;
                this.show_loading = false;
            });
    }

    handleSelect(val: any[]) {
        this.selectIds = val.map((item) => item.id);
    }

    // 全选商品
    handleselectAll() {
        this.$refs.paneTable.toggleAllSelection();
    }

    // 定价
    changePrice(item: any) {
        this.dialog_title = "修改价格";
        this.dialogPrice = true;
        this.is_pick = false;

        if (item.price_type_name === "影院定价") {
            this.priceForm = {
                cinema_id: item.cinema_id,
                price_method: item["movie_cinema_index"]["price_method"],
                value: item["movie_cinema_index"]["value"],
            };
            return;
        }
        this.priceForm = {
            cinema_id: item.cinema_id,
            price_method: "3",
            value: {
                price_list: {
                    price_type: "1",
                    price_ratio: "1",
                    price_add_sub: "1",
                    price_number: "1",
                },
                price_rounding: "1",
                reserve_decimal: 2,
            },
        };
    }
    // 挑选
    handPick(item: any) {
        this.dialog_title = "挑选加入商城（已选中1个城市）";
        this.dialogShop = true;
        this.pick_out_dialog_loading = true;
        this.is_pick = true;
        this.pickForm.shop_data = [];
        this.pickForm.cinema_id = item.cinema_id;
        this.priceForm = {
            price_method: "3",
            value: {
                price_list: {
                    price_type: "1",
                    price_ratio: "1",
                    price_add_sub: "1",
                    price_number: "1",
                },
                price_rounding: "1",
                reserve_decimal: 2,
            },
        };
        apiShopLists({
            page_type: 0,
            app_id: 10,
        }).then((res) => {
            this.shop_list = res.lists;
            this.pick_out_dialog_loading = false;
        });
    }
    // 确认商城
    async handleConfirmShop() {
        if (this.pickForm.shop_data.length === 0) {
            this.$message.warning("请选择商城");
            return;
        }
        this.dialog_title = "设置价格";
        this.dialogShop = false;
        this.dialogPrice = true;
    }
    // 返回选商城
    resetShop() {
        this.dialogPrice = false;
        this.dialogShop = true;
    }

    // 确认改价
    async handleConfirmPrice() {
        const valid = await this.$refs.priceForm.validate();
        if (!valid) {
            return;
        }
        let data: any = {};
        data = this.is_pick
            ? deepClone({ ...this.priceForm, ...this.pickForm })
            : deepClone(this.priceForm);
        this.price_loading = true;
        try {
            const res = this.is_pick
                ? await cinemaPickOut(data)
                : await setCinemaPrice(data);
            this.$message({
                type: "success",
                message: this.is_pick ? "挑选成功" : "改价成功",
            });
            this.price_loading = false;
            this.dialogPrice = false;
            this.$emit("refresh");
        } catch (error) {
            this.price_loading = false;
        }
    }
}
