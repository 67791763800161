

































































































import { Component, Vue, Watch } from "vue-property-decorator";
import CinemaPane from "@/components/movie/cinema-pane.vue";
import ExportData from "@/components/export-data/index.vue";
import { RequestPaging } from "@/utils/util";
import { cinemaBatchList, areaLists } from "@/api/movie";
import { CinemaStatus } from "@/utils/type";
@Component({
    components: {
        CinemaPane,
        ExportData,
    },
})
export default class Goods extends Vue {
    queryObj: any = {
        cinema_name: "",
        city_id: "",
        cinema_id: "",
    };
    tableint: any = "";
    supplierList: any[] = [];
    areaLists: any[] = [];
    typeList: any = [];
    tabCount = {
        vendibility: 0,
        noshow: 0,
    };

    pager = new RequestPaging({ page: 1, size: 50 });
    activeName: any = "vendibility";
    apiGoodsLists = cinemaBatchList;
    getList(page?: number, type?: number): void {
        page && (this.pager.page = page);
        this.tableint = CinemaStatus[this.activeName];
        this.pager
            .request({
                callback: cinemaBatchList,
                params: {
                    status: CinemaStatus[this.activeName],
                    ...this.queryObj,
                },
            })
            .then((res: any) => {
                this.tabCount = res?.extend;
            });
    }

    handleReset() {
        this.queryObj = {
            cinema_name: "",
            city_id: "",
            cinema_id: "",
        };
        this.getList();
    }

    getGoodsCateList() {
        areaLists({}).then((res: any) => {
            this.areaLists = res?.lists;
            if (res?.lists) {
                this.queryObj.city_id = res.lists[0]["id"];
            }
            this.getList();
        });
    }

    created() {
        this.getGoodsCateList();
        // this.getGoodsOtherList()

        this.typeList = [
            { table: "待审核", value: 0 },
            { table: "审核失败", value: 1 },
            { table: "审核成功", value: 2 },
        ];
    }
}
